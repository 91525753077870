import React from 'react';

export const Flyer = ({close}) => {
    return (
        <div className="modal" style={{ background: 'white', padding: '1.5rem', overflow: 'scroll', height: 'auto', inset:"1rem", position:"absolute", maxWidth:'700px', margin:"auto" }}>
            <div className="close" style={{ cursor: 'pointer' }} onClick={close}>X Cerrar</div>
            <div className="text">
                <h2 style={{ color: 'red', textAlign: 'center' }}>¡AVISO IMPORTANTE!</h2>
                <p style={{ textAlign: 'justify' }}>
                    Hemos detectado páginas apócrifas y mensajes de personal no relacionado a <b>FOCRECE</b> usando la
                    imagen de <b>Nuevo Impulso Nuevo León</b> invitando a contraer créditos, en domicilios privados y con
                    el engaño de ser créditos preferenciales o incluso a fondo perdido. Por ello alertamos a la
                    ciudadanía a no caer presa de estos intentos de fraude, <b>nuestros trámites son publicados en
                        nuestra página oficial</b> <a style={{ color: 'cornflowerblue' }} href="https://www.impulsonuevoleon.gob.mx" target="_blank" rel="noopener noreferrer">www.impulsonuevoleon.gob.mx</a>, la información está fácilmente
                    identificable por programa, los registros son vía digital directamente en la página oficial y por
                    ningún motivo se cobra o se requiere el pago de ninguna forma para ser elegibles o poder
                    participar. Bajo ninguna circunstancia el personal de <b>Nuevo Impulso Nuevo León</b> va a requerirle
                    ningún depósito, adelanto o dinero en ninguna parte del proceso. Los trámites son totalmente
                    gratuitos.
                </p>
                <p style={{ textAlign: 'justify' }}>
                    Sea escéptico ante correos electrónicos, mensajes de texto o llamadas telefónicas no solicitados
                    que le pidan información personal o financiera. No haga clic en enlaces o descargue archivos
                    adjuntos de remitentes desconocidos.
                </p>
                <p style={{ textAlign: 'justify' }}>
                    Las páginas de internet oficiales de las entidades gubernamentales ya sea de nivel federal como
                    estatal o municipal, al igual que del programa <b>Nuevo Impulso Nuevo León</b>, tienen la extensión
                    .gob.mx; evite ser víctima de fraudes, valide que las direcciones de las páginas que navega sean las
                    correctas, que los correos tengan las extensiones correspondientes, y si tiene duda, llame a los
                    teléfonos de contacto de las dependencias publicados en sus páginas oficiales.
                </p>
                <p style={{ textAlign: 'justify' }}>
                    <b>Impulso Nuevo León</b> tiene los siguientes medios de contacto:
                </p>
                <ul>
                    <li>Teléfonos en horario de 9:00 am a 5:00 pm: 81 2033 3194 y 81 2033 3263</li>
                    <li>Correo: info@impulsonuevoleon.gob.mx</li>
                    <li>Dirección de página oficial: <a style={{ color: 'cornflowerblue' }} href="https://www.impulsonuevoleon.gob.mx" target="_blank" rel="noopener noreferrer">www.impulsonuevoleon.gob.mx</a></li>
                    <li>Oficinas: Torre Administrativa del Gobierno del Estado de Nuevo León, ubicado en Washington #2000 Piso 13, Col. Obrera, Monterrey, N.L. CP 64010</li>
                </ul>
            </div>
        </div>
    );
};
